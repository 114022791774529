import React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion, useViewportScroll } from 'framer-motion'
import { useIntl, Link, FormattedMessage, navigate } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FixedRegister from '../components/fixed-register'
import * as System from '../../design-system'
import { themes } from '../../base'
import { Guard, isClient } from '../../base/utils'
import { googleAPIKey } from '../../base/consts'
import * as Icon from '../../design-system/components/Icons'
import bgA from '../images/A_2x@.png'
import bgB from '../images/B_2x@.png'
import bgC from '../images/C_2x@.png'
import bgD from '../images/D_2x@.png'
import usePopup from '../store/hooks/usePopup'
import useTheme from '../store/hooks/useTheme'
import Ruucm from '../images/ruucm.jpeg'
import Hyunsu from '../images/plusx.jpeg'
import Minhee from '../images/minhee.jpeg'
import Jinjae from '../images/jin.jpeg'

const ContentWrap = styled.div`
  font-size: 40;
  line-height: 1.8;
  width: 100%;
  padding-bottom: 180px;
  position: relative;
`
const Block = styled.div`
  @media (max-width: 1080px) {
    margin-left: 22px;
    margin-right: 22px;
  }
`

const FullBlock = styled.div``
const CardLgContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`
const CardMdContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }
`
const ContainerTwo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`

const ProfileWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 96px;
  align-items: start;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    grid-gap: 22px;
  }
`

const ContentWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  @media (max-width: 1080px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`
const Scroller = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  margin-top: -288px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.tablet}px) {
        margin-top: 72px;
      }
    `}
`

const ScrollContent = () => {
  const intl = useIntl()
  const tDatas = [
    {
      title: intl.formatMessage({
        id: 'home.talks.content.t1.title',
      }),
      workplace: intl.formatMessage({
        id: 'home.talks.content.t1.workplace',
      }),
      jobtitle: intl.formatMessage({
        id: 'home.talks.content.t1.jobtitle',
      }),
      speaker: intl.formatMessage({
        id: 'home.talks.content.t1.speaker',
      }),
      desc: intl.formatMessage({
        id: 'home.talks.content.t1.desc',
      }),
      summary: intl.formatMessage({
        id: 'home.talks.content.t1.summary',
      }),
      file: 'https://www.notion.so/harbor/a2992f075ebc4661b475da9821b3014d',
    },
    {
      title: intl.formatMessage({
        id: 'home.talks.content.t2.title',
      }),
      workplace: intl.formatMessage({
        id: 'home.talks.content.t2.workplace',
      }),
      jobtitle: intl.formatMessage({
        id: 'home.talks.content.t2.jobtitle',
      }),
      speaker: intl.formatMessage({
        id: 'home.talks.content.t2.speaker',
      }),
      desc: intl.formatMessage({
        id: 'home.talks.content.t2.desc',
      }),
      summary: intl.formatMessage({
        id: 'home.talks.content.t2.summary',
      }),
      file: 'https://harbor.school/why-framer/',
    },
    {
      title: intl.formatMessage({
        id: 'home.talks.content.t3.title',
      }),
      workplace: intl.formatMessage({
        id: 'home.talks.content.t3.workplace',
      }),
      jobtitle: intl.formatMessage({
        id: 'home.talks.content.t3.jobtitle',
      }),
      speaker: intl.formatMessage({
        id: 'home.talks.content.t3.speaker',
      }),
      desc: intl.formatMessage({
        id: 'home.talks.content.t3.desc',
      }),
      summary: intl.formatMessage({
        id: 'home.talks.content.t3.summary',
      }),
      file: 'https://www.notion.so/harbor/1ada4832ac6b4c589a7441c9e66e79f1',
    },
    {
      title: intl.formatMessage({
        id: 'home.talks.content.t4.title',
      }),
      workplace: intl.formatMessage({
        id: 'home.talks.content.t4.workplace',
      }),
      jobtitle: intl.formatMessage({
        id: 'home.talks.content.t4.jobtitle',
      }),
      speaker: intl.formatMessage({
        id: 'home.talks.content.t4.speaker',
      }),
      desc: intl.formatMessage({
        id: 'home.talks.content.t4.desc',
      }),
      summary: intl.formatMessage({
        id: 'home.talks.content.t4.summary',
      }),
      file: 'https://www.notion.so/harbor/25ce33375ba04f74bd883b8649c8bff6',
    },
  ]
  const [theme, setTheme] = useTheme()
  const selectedTheme = themes[theme]
  const [popup, setPopup] = usePopup()

  return (
    <Scroller
      className="scroller"
      selectedTheme={selectedTheme}
      onDoubleClick={setTheme}
    >
      <Block>
        <System.Text text={intl.formatMessage({ id: 'home.intro.title' })} />
        <System.Spacing height="32" />
        <System.Text
          text={intl.formatMessage({ id: 'home.intro.desc' })}
          type="Body"
        />
      </Block>
      <Block>
        <System.Spacing height="120" mobileHeight="70" />
        <System.Text text={intl.formatMessage({ id: 'home.talks.title' })} />
        <System.Spacing height="32" />
        <CardLgContainer>
          <System.CardLg
            {...tDatas[0]}
            bg={bgA}
            onClick={() => {
              setPopup({
                isOpened: true,
                data: tDatas[0],
              })
            }}
          />
          <System.CardLg
            {...tDatas[1]}
            bg={bgB}
            onClick={() => {
              setPopup({
                isOpened: true,
                data: tDatas[1],
              })
            }}
          />
          <System.CardLg
            {...tDatas[2]}
            bg={bgC}
            onClick={() => {
              setPopup({
                isOpened: true,
                data: tDatas[2],
              })
            }}
          />
          <System.CardLg
            {...tDatas[3]}
            bg={bgD}
            onClick={() => {
              setPopup({
                isOpened: true,
                data: tDatas[3],
              })
            }}
          />
        </CardLgContainer>
      </Block>
      <Block>
        <System.Spacing height="120" mobileHeight="70" />
        <ContainerTwo>
          <System.Text text={intl.formatMessage({ id: 'home.snt.title' })} />
          <System.Text
            text={intl.formatMessage({ id: 'home.snt.desc' })}
            type="Body"
          />
        </ContainerTwo>
        <System.Spacing height="32" />
        <CardMdContainer>
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t1.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t1.speaker',
            })}
            file="https://www.notion.so/harbor/405da8fe482e4cf992de867949fe5a49"
          />
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t2.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t2.speaker',
            })}
            file="https://www.notion.so/harbor/ecfb6f09bf6142cd98465e6e356bb218"
          />
          {/* <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t3.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t3.speaker',
            })}
          /> */}
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t4.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t4.speaker',
            })}
            file="https://www.notion.so/harbor/2c63ce4d6676491ab2eb51052e80883a"
          />
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t5.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t5.speaker',
            })}
            file=""
          />
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t6.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t6.speaker',
            })}
            file="https://www.notion.so/harbor/68d616f8f86a44e59e0394b0ac812a63"
          />
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t7.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t7.speaker',
            })}
            file="https://www.notion.so/harbor/953f6f88739b4b138e2f6f04f267a348"
          />
          <System.CardMd
            title={intl.formatMessage({
              id: 'home.snt.content.t8.title',
            })}
            speaker={intl.formatMessage({
              id: 'home.snt.content.t8.speaker',
            })}
            file="https://www.notion.so/harbor/43ce726698394de7ad8c76f083065404"
          />
        </CardMdContainer>
      </Block>
      <FullBlock>
        <System.Spacing height="120" mobileHeight="70" />
        <div
          style={{
            marginLeft: 22,
            marginRight: 22,
          }}
        >
          <System.Text
            text={intl.formatMessage({ id: 'home.workshop.title' })}
          />
        </div>
        <System.Spacing type="title" />
        <System.Workshop
          title={intl.formatMessage({ id: 'home.workshop.subtitle' })}
          body={intl
            .formatMessage({ id: 'home.workshop.desc' })
            .replace('\n', '<br />')}
        />
      </FullBlock>
      <FullBlock>
        <System.Spacing height="120" mobileHeight="70" />
        <div
          style={{
            marginLeft: 22,
            marginRight: 22,
          }}
        >
          <System.Text
            text={intl.formatMessage({ id: 'home.timetable.title' })}
          />
        </div>
        <System.Spacing height="32" />
        <System.TimeTableWeb />
        <System.TimeTableMobile />
      </FullBlock>
      <Block>
        <System.Spacing height="120" mobileHeight="70" />
        <System.Text text={intl.formatMessage({ id: 'home.location.title' })} />
        <System.Spacing height="32" />
        <ContainerTwo>
          <System.CardXL>
            <ContentWrapper>
              <System.Spacing height="24" />
              <div
                style={{
                  width: 26,
                  height: 35.29,
                }}
              >
                <Icon.Location fill={selectedTheme.color.secondaryText} />
              </div>
              <System.Spacing height="24" />
              <System.Text
                type="Heading-2"
                text={intl.formatMessage({
                  id: 'home.location.location',
                })}
              />
              <System.Spacing height="8" />
              <System.Text
                type="Body"
                text={intl.formatMessage({ id: 'home.location.address' })}
              />
              <System.Spacing height="32" mobileHeight="24" />
              <a href="http://naver.me/FvitYwRJ" target="_blank">
                <System.Text
                  type="Body"
                  style={{ color: '#1a73e8', fontWeight: '700' }}
                  text={intl.formatMessage({ id: 'home.location.link' })}
                />
              </a>
              <System.Spacing height="24" />
            </ContentWrapper>
          </System.CardXL>
          <System.CardXL>
            <System.Map googleAPIKey={googleAPIKey} />
          </System.CardXL>
        </ContainerTwo>
      </Block>
      <Block>
        <System.Spacing height="120" mobileHeight="70" />
        <System.Text
          text={intl.formatMessage({ id: 'home.framerKorea.title' })}
        />
        <System.Spacing height="32" />
        <System.Text
          text={intl.formatMessage({ id: 'home.framerKorea.desc' })}
          type="Body"
        />
        {intl.locale === 'ko' && (
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScUBjYTs3w8L_UuJfy0-P57tpB1PfPCCpySz8Lntm8GS2hRbA/viewform?usp=sf_link"
            target="_blank"
          >
            <System.Text
              text="(오픈 채팅방 참여하기)"
              type="Body"
              style={{
                color: selectedTheme.color.framerPurple,
              }}
            />
          </a>
        )}
        <System.Spacing height="32" />
        <ProfileWrap>
          <System.ProfileCard
            name={intl.formatMessage({
              id: 'home.framerKorea.member.t1.name',
            })}
            nickname={intl.formatMessage({
              id: 'home.framerKorea.member.t1.nickname',
            })}
            role={intl.formatMessage({
              id: 'home.framerKorea.member.t1.role',
            })}
            link={intl.formatMessage({
              id: 'home.framerKorea.member.t1.link',
            })}
            image={Ruucm}
          />
          <System.ProfileCard
            name={intl.formatMessage({
              id: 'home.framerKorea.member.t2.name',
            })}
            nickname={intl.formatMessage({
              id: 'home.framerKorea.member.t2.nickname',
            })}
            role={intl.formatMessage({
              id: 'home.framerKorea.member.t2.role',
            })}
            link={intl.formatMessage({
              id: 'home.framerKorea.member.t2.link',
            })}
            image={Jinjae}
          />
          <System.ProfileCard
            name={intl.formatMessage({
              id: 'home.framerKorea.member.t3.name',
            })}
            nickname={intl.formatMessage({
              id: 'home.framerKorea.member.t3.nickname',
            })}
            role={intl.formatMessage({
              id: 'home.framerKorea.member.t3.role',
            })}
            link={intl.formatMessage({
              id: 'home.framerKorea.member.t3.link',
            })}
            image={Hyunsu}
          />
          <System.ProfileCard
            name={intl.formatMessage({
              id: 'home.framerKorea.member.t4.name',
            })}
            nickname={intl.formatMessage({
              id: 'home.framerKorea.member.t4.nickname',
            })}
            role={intl.formatMessage({
              id: 'home.framerKorea.member.t4.role',
            })}
            link={intl.formatMessage({
              id: 'home.framerKorea.member.t4.link',
            })}
            image={Minhee}
          />
        </ProfileWrap>
      </Block>
      <Block>
        <System.Spacing height="120" mobileHeight="70" />
        <System.Text text={intl.formatMessage({ id: 'home.sponsor.title' })} />
        <System.Spacing height="38" />
        <System.Sponsor />
      </Block>
    </Scroller>
  )
}

const IndexPage = () => {
  const guard = Guard()
  if (guard && isClient) navigate('/are-you-having-fun')
  const intl = useIntl()

  return guard ? (
    <Layout>
      <SEO
        title="Framer Korea Meetup"
        description="Framer X, 잘 쓰고 계신가요?"
        metaImage={require('../images/Facebook.png')}
      />
    </Layout>
  ) : (
    <Layout>
      <SEO
        title="Framer Korea Meetup"
        description="Framer X, 잘 쓰고 계신가요?"
        metaImage={require('../images/Facebook.png')}
      />
      <System.Top
        desc1={intl.formatMessage({ id: 'home.title.desc1' })}
        desc2={intl.formatMessage({ id: 'home.title.desc2' })}
        registerText={intl.formatMessage({ id: 'home.register' })}
        // scrollYProgress={scrollYProgress}
      />
      <ContentWrap>
        <System.LNB />
        <ScrollContent />
      </ContentWrap>
      <FixedRegister
        registerText={intl.formatMessage({ id: 'home.register' })}
      />
    </Layout>
  )
}
IndexPage.defaultProps = {
  theme: 'light',
}

export default IndexPage
