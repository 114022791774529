import React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useContext, useState } from 'react'
import {
  motion,
  useViewportScroll,
  useAnimation,
  transform,
} from 'framer-motion'
import { themes } from '../../base'
import { scrollToTop, isClient, isMobile } from '../../base/utils'
import * as Icon from '../../design-system/components/Icons'
import * as System from '../../design-system'
import { checkoutUrl, SecondaryEase, defaultEase } from '../../base/consts'

const Wrap = styled(motion.div)`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
`

const GotoTopButton = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 200px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.tablet}px) {
        right: 20px;
      }
    `}
`
const FixedRegister = ({ registerText, theme, ...props }) => {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const [show, setShow] = useState(false)
  const [showTop, setShowTop] = useState(false)
  const { scrollY } = useViewportScroll()
  scrollY.onChange(move => {
    setShow(transform(move, [560, 559, 0], [1, 0, 0]))
    setShowTop(transform(move, [750, 750, 0], [1, 0, 0]))
  })
  return (
    <Wrap
      initial={{
        bottom: -68,
      }}
      animate={{
        bottom: show ? (isMobile ? 20 : 40) : -68,
      }}
      transition={SecondaryEase}
    >
      <a href={checkoutUrl} target="_blank">
        <System.Button text={registerText} />
      </a>
      <GotoTopButton
        selectedTheme={selectedTheme}
        animate={{
          opacity: showTop ? 1 : 0,
        }}
        transition={defaultEase}
        onClick={() => isClient && scrollToTop()}
      >
        <System.GotoTopButton />
      </GotoTopButton>
    </Wrap>
  )
}
FixedRegister.defaultProps = {
  theme: 'light',
  registerText: 'registerText',
}

export default FixedRegister
